import React from "react";
import { graphql, Link } from "gatsby";
import { startCase } from "lodash";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Promo from "../components/blogs/promo/Promo";
import ArticleList from "../components/articleList/ArticleList";
import Navbar from "../components/home/navbar/Navbar";
import Footer from "../components/home/footer/Footer";
import BlogSideBar from "../components/blogs/blogSideBar/BlogSideBar";
import * as styles from "./posts.module.css";
import SEO from "../components/Seo";
import moment from "moment";
import SocialShare from "../components/SocialShare";
import Articles from "../components/home/articles/Articles";

const SinglePost = ({ data, pageContext }) => {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]');
  }
  const next = pageContext.next
    ? {
        url: `/posts/${pageContext.next.frontmatter.slug}/`,
        title: pageContext.next.frontmatter.title,
      }
    : null;
  const prev = pageContext.prev
    ? {
        url: `/posts/${pageContext.prev.frontmatter.slug}/`,
        title: pageContext.prev.frontmatter.title,
      }
    : null;
  const disqusConfig = {
    /* Replace PAGE_URL with your post's canonical URL variable */
    url: data.mdx.slug,
    /* Replace PAGE_IDENTIFIER with your page's unique identifier variable */
    identifier: data.mdx.id,
    /* Replace PAGE_TITLE with the title of the page */
    title: data.mdx.frontmatter.title,
  };

  const tableOfContents = data.mdx.tableOfContents;
  // const seoImage =
  //   data.mdx.frontmatter.og_image !== undefined &&
  //   data.mdx.frontmatter.og_image !== null &&
  //   data.mdx.frontmatter.og_image.publicURL !== null &&
  //   data.mdx.frontmatter.og_image.publicURL !== undefined
  //     ? data.mdx.frontmatter.og_image.publicURL
  //     : "";

  const image =
    data.mdx.frontmatter.image !== undefined &&
    data.mdx.frontmatter.image !== null
      ? data.mdx.frontmatter.image
      : data.mdx.frontmatter.og_image !== undefined &&
        data.mdx.frontmatter.og_image !== null &&
        data.mdx.frontmatter.og_image.publicURL !== null &&
        data.mdx.frontmatter.og_image.publicURL !== undefined
      ? data.mdx.frontmatter.og_image.publicURL
      : "";

  const getSocialShareTitle = () => {
    return `${data.mdx.frontmatter.title} by @sleeplessyogi \n`;
  };

  return (
    <>
      <Navbar />
      <SEO
        titleStub={data.mdx.frontmatter.title}
        urlStub={`/posts/${pageContext.slug}/`}
        description={data.mdx.frontmatter.description}
        image={image}
      />
      <div
        className={`${styles.singlePostWrapper} grid grid-cols-1 gap-2 sm:grid-cols-3`}
      >
        <div className={`single-post col-span-2`}>
          <h1 className="title">{data.mdx.frontmatter.title}</h1>
          {/* <div className="img-wrap">
            <img
              src={image}
              alt={data.mdx.frontmatter.title}
              className="post-image"
            />
          </div> */}
          <div className="tagsWrapper">
            {"Tags: "}
            {data.mdx.frontmatter.tags &&
              data.mdx.frontmatter.tags.map((t, index) => (
                <span key={index}>
                  <Link to={`/tag/${t}/`} title={t} target="_blank">
                    <span key={t}>{startCase(t)}</span>
                  </Link>
                  {index !== data.mdx.frontmatter.tags.length - 1 ? ", " : ""}
                </span>
              ))}
            <div className="mt-2">
              {moment(data.mdx.frontmatter.date).format("DD MMMM YYYY")}
            </div>
          </div>
          <div className="tocWrapper">
            <h3>Table Of Content</h3>
            <ul>
              {typeof tableOfContents !== undefined &&
                tableOfContents.items.map((item) => {
                  if (!item.url) {
                    return undefined;
                  }

                  return (
                    <li key={item.url}>
                      <a href={item.url}>{item.title} </a>
                    </li>
                  );
                })}
            </ul>
          </div>
          <MDXRenderer>{data.mdx.body}</MDXRenderer>
          <br />
          <a href="https://etsy.me/3OhN5Yk" target="_blank">
            <img
              src="https://i.imgur.com/VbOcP48.webp"
              alt="Try Etsy For Free"
            ></img>
          </a>
          <br />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              margin: "20px 0px 100px 0px",
            }}
          >
            {prev && (
              <Link to={prev.url}>
                <span
                  style={{
                    fontSize: "18px",
                    borderBottom: "1px solid black",
                  }}
                  className="text-black"
                >
                  <span className="text-purple-800">Previous:</span>{" "}
                  {prev.title}
                </span>
                {/* <h3>{prev.title}</h3> */}
              </Link>
            )}
            {next && (
              <Link to={next.url}>
                <span
                  style={{
                    fontSize: "18px",
                    borderBottom: "1px solid black",
                  }}
                  className="text-black"
                >
                  <span className="text-purple-800">Next:</span> {next.title}
                </span>
                {/* <h3>{next.title}</h3> */}
              </Link>
            )}
          </div>
          <div>
            <h3>Share This Post</h3>
            <SocialShare
              getSocialShareTitle={getSocialShareTitle}
            ></SocialShare>
          </div>
        </div>
        <div className="sideBarWrapper">
          <BlogSideBar />
        </div>
      </div>
      <ArticleList themeClass={"ternary"} />
      <Articles sectionTitle="Keep Reading" />
      <Promo />
      <Footer />
    </>
  );
};

export default SinglePost;

export const pageQuery = graphql`
  query SinglePostQuery($id: String!) {
    mdx(id: { eq: $id }) {
      id
      tableOfContents
      frontmatter {
        date
        description
        slug
        tags
        title
        og_image {
          publicURL
        }
        image
      }
      body
    }
  }
`;
